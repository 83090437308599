import React, { Component } from "react";

export default class Achievements extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <>
        <div className="topbar">Topbar</div>
      </>
    );
  }
}
